import { v4 as uuidv4 } from "uuid";

const dataSlider = [
  {
  id: uuidv4(),
  name: "Nara Johnson",
  position: "CEO",
  email: "johnsonnara5479\n@students.esuhsd.org",
  phone: "(408)-612-7907",
  },
  {
  id: uuidv4(),
  name: "Venice Meil Metra",
  position: "CFO",
  email: "metravenicemeil4603\n@students.esuhsd.org",
  phone: "(408)-332-7498",
  },
  {
  id: uuidv4(),
  name: "Anabel Sairs",
  position: "COO In-House Printing",
  email: "sairsanabel9782\n@students.esuhsd.org",
  phone: "(669)-476-7608",
  },
  /*
  {
  id: uuidv4(),
  name: "Nara Johnson",
  position: "VP of Graphic Design",
  email: "johnsonnara5479\n@students.esuhsd.org",
  phone: "(408)-612-7907",
  },
  {
  id: uuidv4(),
  name: "Himani Manjunath",
  position: "VP of Web Development",
  email: "manjunathhimani3485\n@students.esuhsd.org",
  phone: "(669)-264-6395",
  },
  {
  id: uuidv4(),
  name: "Venezia Mejia",
  position: "VP of Web Development",
  email: "mejiavenezia0399\n@students.esuhsd.org",
  phone: "(408)-412-2734",
  },
  */
  {
  id: uuidv4(),
  name: "Lucas Busta",
  position: "Director of DTG",
  email: "bustalucas4712\n@students.esuhsd.org",
  phone: "(408)-769-0507",
  },
  {
  id: uuidv4(),
  name: "Truman Luong",
  position: "Director of Specialty Printing",
  email: "luongtruman4858\n@students.esuhsd.org",
  phone: "(408)-722-7295",
  },
  {
    id: uuidv4(),
    name: "James Hermans",
    position: "VP of Web Development",
    email: "hermansjames4441\n@students.esuhsd.org",
    phone: "(408)-905-9606",
  },
  {
    id: uuidv4(),
    name: "Varun Madhan",
    position: "VP of Web Development",
    email: "madhankumarlaksh2869@students.esuhsd.org",
    phone: "(669)-306-2312",
  },
  /*
  {
  id: uuidv4(),
  name: "Logan Hua",
  position: "Director of Treasure Trove",
  email: "hualogan5244\n@students.esuhsd.org",
  phone: "(669)-254-7358",
  }
  */
];

export default dataSlider;