import { v4 as uuidv4 } from "uuid";

const dataSlider = [
  ///to do 
  {
    id: uuidv4(),
    title: "Back in Business!",
    subTitle: "Opening Message",
    link: "https://insaneink.com/howitworks",
  },

  {
    id: uuidv4(),
    title: "Stickers, Posters, and More!",
    subTitle: "Roland",
    link: "https://insaneink.com/roland",
  },

  {
    id: uuidv4(),
    title: "Treasure Trove",
    subTitle: "PHHS Merch!",
    link: "https://insaneink.com/treasuretrove",
  }
];

export default dataSlider;